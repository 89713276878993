import { COMPANY, FOUNDER, INVESTOR, ENTHUSIAST } from "./account-types";

export const SET_AUTH_STATE = "SET_AUTH_STATE";
export const SIGNIN_WITH_EMAIL_AND_PASSWORD = "SIGNIN_WITH_EMAIL_AND_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CONFIRM_RESET_PASSWORD = "CONFIRM_RESET_PASSWORD";
export const SIGNUP_WITH_EMAIL_AND_PASSWORD = "SIGNUP_WITH_EMAIL_AND_PASSWORD";
export const SIGNUP_VERIFY_ACCOUNT = "SIGNUP_VERIFY_ACCOUNT";
export const SIGNIN_VERIFY_ACCOUNT = "SIGNIN_VERIFY_ACCOUNT";
export const RESEND_VERIFICATION_CODE = "RESEND_VERIFICATION_CODE";
export const RESEND_ATTRIBUTES_VERIFICATION_CODE = "RESEND_ATTRIBUTES_VERIFICATION_CODE";
export const CHECK_ACCOUNT_EXISTS = "CHECK_ACCOUNT_EXISTS";

export const AUTH_SIGNIN = 0;
export const AUTH_SIGNUP = 1;
export const AUTH_SIGNUP_PASSWORD = 2;
export const AUTH_SIGNUP_VERIFY_ACCOUNT = 3;
export const AUTH_RESET_PASSWORD = 4;
export const AUTH_RESET_VERIFICATION = 5;
export const AUTH_CONFIRM_RESET_PASSWORD = 6;
export const AUTH_ACCOUNT_VERIFICATION_CODE = 7;
export const AUTH_SIGNIN_VERIFY_ACCOUNT = 8;
export const AUTH_CHOOSE_ACCOUNT_TYPE = 9;
export const AUTH_WELCOME_TO_RADIVISION = 10;

export const ACCOUNT_TYPES = [ENTHUSIAST, FOUNDER];

export const AUTHENTICATION_MODAL_ID = "authentication-modal";
