import * as React from "react";

export const INVESTOR = {
  kind: "INVESTOR",
  title: "I am an investor",
  description: "Looking to discover amazing startups and connect directly? This is the account for you!",
  colors: ["#1A2980", "#26D0CE"],
};

export const FOUNDER = {
  kind: "FOUNDER",
  title: "I am a Startup Founder",
  description:
    "I’m honored to share my company, products, and the results we are creating for investors, customers, and the world.",
  colors: ["#02d7a7", "#0120ac", "#5a00c4"],
};

export const COMPANY = {
  kind: "COMPANY",
  title: "I am a Startup Ecosystem Participant",
  description: (
    <>
      Showcase your amazing company to 1,000s of entrepreneurial enthusiasts and potential investors. Get featured on
      Radivision and social media, apply to Pitch Planet&#8482; and other original series.
    </>
  ),
  colors: ["#6107BC", "#C210DA"],
};

export const ENTHUSIAST = {
  kind: "ENTHUSIAST",
  title: "I am a Startup Enthusiast",
  description: "I’m excited to discover innovative startups, products, people, and trends in the startup ecosystem, and to participate in the solutions and prosperity being created.",
  colors: ["#0814A8", "#8800C1", "#DD1726"],
};
