import React from "react";
import { Modal } from "../modal";
import $ from "jquery";
import { connect } from "react-redux";
import * as Actions from "../../../redux/actions";
import { AppState } from "../../../redux";
import {
  AUTHENTICATION_MODAL_ID,
  AUTH_CHOOSE_ACCOUNT_TYPE,
  AUTH_CONFIRM_RESET_PASSWORD,
  AUTH_RESET_PASSWORD,
  AUTH_RESET_VERIFICATION,
  AUTH_SIGNIN,
  AUTH_SIGNIN_VERIFY_ACCOUNT,
  AUTH_SIGNUP,
  AUTH_SIGNUP_PASSWORD,
  AUTH_SIGNUP_VERIFY_ACCOUNT,
  AUTH_WELCOME_TO_RADIVISION,
} from "../../../redux/authentication/constants";
import { SignIn } from "./signin";
import { SignUp } from "./signup";
import { ResetPassword } from "./reset-password";
import { ConfirmResetPassword } from "./confirm-reset-password";
import { ResetVerificationCode } from "./reset-verification-code";
import { ChooseAccountType } from "./choose-account-type";
import { SignUpVerifyAccount } from "./signup-verify-account";
import { SignInVerifyAccount } from "./signin-verify-account";
import { SignUpPassword } from "./signup-password";
import { WelcomeToRadivision } from "./welcome-to-radivision";
import { SVG } from "../../../component-configuration/svgs";
import { SetAuthStatePayload, SetAuthStateAction } from "../../../redux/authentication/types";
import background from "../../../../static/choose_your_account_alloons.jpg";
import { ConfirmationModal } from "../confirmation-modal";
import greyBackground from "../../../../static/123.jpg";

interface MapStateProps {
  step: number;
}

interface MapDispatchProps {
  setAuthState: (payload: SetAuthStatePayload) => SetAuthStateAction;
}

const modalId = "closeSignup";

export type Props = MapStateProps & MapDispatchProps;

const cacheImages = async (srcArray) => {
  const promises = await srcArray.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.src = src;
      img.onload = resolve();
      img.onerror = reject();
    });
  });

  await Promise.all(promises);
};


export const Component = (props: Props) => {
  const { step, setAuthState } = props;


  const [greyScale, setgreyScaled] = React.useState(false);

  const IMAGES = [
    "https://content.radivision.com/images/colored_balloons.jpg?wip=720",
    "https://content.radivision.com/images/grey_balloons.jpg?wip=720",
  ];
  cacheImages(IMAGES);


  let title = "";
  if (step === AUTH_SIGNIN) {
    title = "Log in";
  }
  if (step === AUTH_SIGNUP) {
    title = "Join Radivision";
  }
  if (step === AUTH_SIGNUP_PASSWORD) {
    title = "Set a password";
  }
  if (step === AUTH_SIGNUP_VERIFY_ACCOUNT) {
    title = "Enter verification code";
  }
  if (step === AUTH_SIGNIN_VERIFY_ACCOUNT) {
    title = "Enter verification code";
  }
  if (step === AUTH_RESET_PASSWORD) {
    title = "Forgot password?";
  }
  if (step === AUTH_RESET_VERIFICATION) {
    title = "Enter verification code";
  }
  if (step === AUTH_CONFIRM_RESET_PASSWORD) {
    title = "Reset the Password";
  }
  if (step === AUTH_CHOOSE_ACCOUNT_TYPE) {
    title = "Choose Account Type";
  }
  if (step === AUTH_WELCOME_TO_RADIVISION) {
    title = "Welcome to Radivision";
  }
  const isCreateProfile = step === AUTH_WELCOME_TO_RADIVISION;

  const stepsNeedMoreHelp = [
    AUTH_SIGNUP_PASSWORD,
    AUTH_SIGNUP_VERIFY_ACCOUNT,
    AUTH_CHOOSE_ACCOUNT_TYPE,
    AUTH_SIGNIN_VERIFY_ACCOUNT,
    AUTH_RESET_PASSWORD,
    AUTH_RESET_VERIFICATION,
    AUTH_CONFIRM_RESET_PASSWORD,
    AUTH_WELCOME_TO_RADIVISION,
  ];

  const stepsRequiringConfirmation = [AUTH_SIGNUP_PASSWORD, AUTH_SIGNUP_VERIFY_ACCOUNT, AUTH_CHOOSE_ACCOUNT_TYPE];

  const confirmClodeSignup = React.useCallback(() => {
    if (stepsRequiringConfirmation.includes(step)) {
      $(`#${modalId}`).modal("show");
    } else {
      $(`#${AUTHENTICATION_MODAL_ID}`).modal("hide");
    }
  }, [stepsRequiringConfirmation, step]);

  const confirmClose = React.useCallback(() => {
    $(`#${modalId}`).modal("hide");
    $(`#${AUTHENTICATION_MODAL_ID}`).modal("hide");
  }, [modalId]);

  return (
    <div>
      <Modal
        id={`${AUTHENTICATION_MODAL_ID}`}
        title={title}
        className={`form-modal`}
        buttonIcon={isCreateProfile ? SVG.chevronLeft : null}
        buttonClick={isCreateProfile ? () => setAuthState({ step: AUTH_CHOOSE_ACCOUNT_TYPE }) : confirmClodeSignup}
        modalContentStyle={{
          ...(step === AUTH_CHOOSE_ACCOUNT_TYPE
            ? {
              // backgroundColor: "rgba(23,23,23,1)",
              // backgroundImage: `url('${!greyScale ? IMAGES[0] : IMAGES[1]}')`,
              // backgroundPosition: "center",
              // backgroundSize: "auto 100vh",
              // backgroundRepeat: "no-repeat",
            }
            : {}),
        }}
      >
        <div
          className={`content`}
          style={{ position: "relative" }}
          onClick={() => {
            setgreyScaled(true)
          }}
        >
          <SignIn />
          <SignUp />
          <SignUpPassword />
          <SignUpVerifyAccount />
          <SignInVerifyAccount />
          <ResetPassword />
          <ResetVerificationCode />
          <ConfirmResetPassword />
          <ChooseAccountType />
          <WelcomeToRadivision />
          <p class="mt-2 text-white">
            Need help?{" "}
            <a
              href="https://radivision.com/get-started"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.preventDefault();
                const startedURL = "https://radivision.com/get-started";
                window.open(startedURL, "_blank");
              }}
              style={{ color: "#4cb4e7", cursor: "pointer" }}
            >
              Get Started
            </a>
          </p>
        </div>
      </Modal>
      <ConfirmationModal
        id={modalId}
        message={"Are you sure you want to quit the Sign Up process?"}
        confirm={confirmClose}
        cancelBtnText="Continue to sign up"
        yesBtnText="Finish Later"
      />
    </div>
  );
};

const mapState = (state: AppState) => ({
  step: state.authentication.step,
});

const mapDispatch = {
  setAuthState: Actions.setAuthState,
};

export const AuthenticationModal = connect(mapState, mapDispatch)(Component);
